import { keepPreviousData, queryOptions, useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, MaybeRef, Ref } from 'vue'
import { useRoute } from 'vue-router'

import { useStore } from '../store/useStore'
import { api } from './quantistry'

// @TODO(API) put this in the API schema?
export type OrganizationListItem = Awaited<ReturnType<typeof api.organizations.findMany>>['list'][0]
export type Organization = Awaited<ReturnType<typeof api.organizations.findOne>>

export function useCurrentOrganizationId() {
  const route = useRoute()
  // optional `route` because `storybook-vue3-router` doesn't work properly with storybook@7-rc
  const currentOrganizationId = computed(() => route?.params.affiliationId as string | undefined)
  return currentOrganizationId
}

/**
 * Query Keys for organizations
 */
const organizationKeys = {
  lists: () => ['organizations', 'list'] as const,
  details: () => ['organizations', 'detail'] as const,
  detail: (id: MaybeRef<string | undefined>) => ['organizations', 'detail', id] as const,
}

export function useOrganizationQueries() {
  const queryClient = useQueryClient()

  return {
    /** Invalidate all organization lists */
    invalidateOrganizationLists: () =>
      queryClient.invalidateQueries({ queryKey: organizationKeys.lists() }),
    /** Invalidate all organization detail queries (organization by id) */
    invalidateOrganizationDetails: () =>
      queryClient.invalidateQueries({ queryKey: organizationKeys.details() }),
    /** Invalidate specific organization detail query (organization by id) */
    invalidateOrganizationDetail: (id: MaybeRef<string | undefined>) =>
      queryClient.invalidateQueries({ queryKey: organizationKeys.detail(id) }),
  }
}

export function useOrganizationById(organizationId: Ref<string | undefined>) {
  return useQuery({
    staleTime: 1000 * 60,
    enabled: computed(() => !!organizationId.value),
    retry: false, // failed queries will not retry by default
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    queryKey: organizationKeys.detail(organizationId),
    queryFn() {
      return api.organizations.findOne({
        id: organizationId.value as string,
      })
    },
  })
}

export function useCurrentOrganization() {
  const store = useStore()
  const currentOrganizationId = useCurrentOrganizationId()
  const key = computed(() =>
    store.state.isAuthenticated && currentOrganizationId.value
      ? currentOrganizationId.value
      : undefined
  )

  return useOrganizationById(key)
}

export function createListOrganizationsQuery() {
  return queryOptions({
    staleTime: 1000 * 60,
    retry: false, // failed queries will not retry by default
    queryKey: organizationKeys.lists(),
    queryFn() {
      return api.organizations.findMany({ include: ['projects'] })
    },
  })
}

export function useOrganizations() {
  return useQuery(createListOrganizationsQuery())
}

export function getUserType(organizations: Awaited<ReturnType<typeof api.organizations.findMany>>) {
  const isEnterprise = organizations?.list.some((org) => org.type === 'team')
  return isEnterprise ? 'enterprise' : 'community'
}
