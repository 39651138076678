import { Project, ProjectInfo } from '@quantistry/api'
import { keepPreviousData, queryOptions, useQuery, useQueryClient } from '@tanstack/vue-query'
import ColorHash from 'color-hash'
import { computed, ComputedRef, MaybeRef, toRef } from 'vue'
import { useRoute } from 'vue-router'

import { useOrganizationQueries } from './organizations'
import { api } from './quantistry'

type ProjectFindManyParams = Parameters<typeof api.projects.findMany>[0]
export interface ProjectListParameters extends ProjectFindManyParams {
  /**
   * Set this to false to disable automatic refetching when the query mounts or changes query keys.
   * To refetch the query, use the `refetch` method returned from the `useQuery` instance.
   * Defaults to `true`.
   */
  enabled?: boolean
}

const generateColor = new ColorHash({ lightness: 0.6 })

export function generateProjectColor(project: Project | { id: string; name: string }) {
  return generateColor.hex(`${project.name}${project.id}`.toLowerCase())
}

export function formatProject(
  project: Project | ProjectInfo,
  organizationId: string,
  { addFavoriteSuffix } = {
    /**
     * Add URL suffix indicating that the link is coming from the
     * favorites section in the navigation sidebar
     */
    addFavoriteSuffix: false,
  }
) {
  const basePath = `/${organizationId}/${project.id}`
  const href = addFavoriteSuffix ? `${basePath}-fav` : basePath

  return {
    id: project.id,
    name: project.name,
    color: project.color,
    href,
  }
}

function stripFavoriteSuffix(projectId: string) {
  return projectId.replace(/-fav$/, '')
}

export function useCurrentProjectId() {
  const route = useRoute()
  const currentProjectId = computed(() =>
    route.params.projectId ? stripFavoriteSuffix(route.params.projectId as string) : undefined
  )
  return currentProjectId
}

/**
 * Query Keys for projects
 */
const projectKeys = {
  lists: () => ['projects', 'list'] as const,
  list: (params: MaybeRef<ProjectListParameters>) => ['projects', 'list', params] as const,
  details: () => ['projects', 'detail'] as const,
  detail: (id: MaybeRef<string | undefined>) => ['projects', 'detail', id] as const,
}

export function useProjectQueries() {
  const queryClient = useQueryClient()
  const { invalidateOrganizationLists } = useOrganizationQueries()

  return {
    /** Invalidate all project lists */
    invalidateProjectLists: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: projectKeys.lists() }),
        // projects are included in the organizations list endpoint (used in the sidebar)
        invalidateOrganizationLists(),
      ])
    },
    /** Invalidate all project lists with the specified parameters */
    invalidateProjectList: (params: MaybeRef<ProjectListParameters>) =>
      queryClient.invalidateQueries({ queryKey: projectKeys.list(params) }),
    /** Invalidate all project detail queries (project by id) */
    invalidateProjectDetails: () =>
      queryClient.invalidateQueries({ queryKey: projectKeys.details() }),
    /** Invalidate specific project detail query (project by id) */
    invalidateProjectDetail: (id: MaybeRef<string | undefined>) =>
      queryClient.invalidateQueries({ queryKey: projectKeys.detail(id) }),
  }
}

export function useProjectById(projectId: MaybeRef<string | undefined>, keepData = true) {
  const projectIdRef = toRef(projectId)
  return useQuery({
    enabled: () => !!projectIdRef.value,
    retry: false, // failed queries will not retry by default
    queryKey: projectKeys.detail(projectIdRef),
    placeholderData: keepData ? keepPreviousData : undefined,
    queryFn() {
      return api.projects.findOne({ id: projectIdRef.value as string })
    },
  })
}

export function useCurrentProject() {
  const currentProjectId = useCurrentProjectId()
  return useProjectById(currentProjectId)
}

export function createListProjectsQuery(parameters?: MaybeRef<ProjectListParameters>) {
  const paramatersRef = toRef(parameters)
  const defaultParameters: ProjectListParameters = { sort: 'name', order: 'asc' }

  const params = computed<ProjectListParameters>(() => {
    return Object.assign({}, defaultParameters, paramatersRef.value)
  })

  return queryOptions({
    staleTime: 1000 * 60,
    enabled: computed(() => paramatersRef.value?.enabled !== false),
    retry: false, // failed queries will not retry by default
    queryKey: projectKeys.list(params),
    async queryFn() {
      return api.projects.findMany(params.value)
    },
  })
}

export function useProjects(parameters?: MaybeRef<ProjectListParameters>) {
  return useQuery(createListProjectsQuery(parameters))
}

export function useProjectInvitations(
  id: ComputedRef<string | undefined>,
  options: { enabled?: ComputedRef<boolean> } = {}
) {
  const { data, error, refetch, isFetching } = useQuery({
    queryKey: ['project', 'invitations', id],
    enabled: computed(() => options.enabled?.value === true && !!id?.value),
    queryFn() {
      return api.projectInvitations.findMany({
        id: id.value as string,
      })
    },
  })

  return { data, error, refetch, isFetching }
}

export function useProjectsAsSelectOptions(parameters?: MaybeRef<ProjectListParameters>) {
  const { data: projects } = useProjects(parameters)

  const options = computed(
    () =>
      projects.value?.list.map((project) => ({
        value: project.id,
        label: project.name,
      })) ?? []
  )

  return options
}
