import { useRouteQuery } from '@vueuse/router'
import * as Comlink from 'comlink'
import { del, get, set } from 'idb-keyval'
import { computed, ComputedRef, Ref, ref, UnwrapRef, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useStore } from '../store/useStore'
import { Eh2eV } from './chemistry'
import { formatNumberScientific } from './utils'
import { useCurrentOrganizationId } from '~/api/organizations'
import { useCurrentProjectId } from '~/api/projects'
import { useWorkflow } from '~/api/workflows'
import { ToastCardType, useToast } from '~/components/Toast/hooks'

type Energy = {
  data: number
  label: string
  units: string
}

export function makeIndexedDbKey(key: IDBValidKey) {
  const { state } = useStore()
  return `${state.user.id}__${key}`
}

export const TASK_HINT = 'task-hint'
export const CURRENT_AFFILIATION = 'current-affiliation'
export const CURRENT_PROJECT_FILTER = 'current-project-filter'
export const CURRENT_WORKFLOW_FILTER = 'current-workflow-filter'

async function getInitialKey<T = unknown>(key: IDBValidKey): Promise<T | undefined> {
  const storedValue = await get(key)
  return storedValue
}

/** Persist a value in a query parameter and IndexedDB. */
export function usePersistedValue(key: string, fallbackValue: string) {
  const route = useRoute()
  const router = useRouter()
  const data = ref(fallbackValue)

  const prefixedKey = makeIndexedDbKey(key)
  if (route.query[key]) {
    data.value = route.query[key] as string
    set(prefixedKey, data.value)
  } else {
    getInitialKey<string>(prefixedKey).then((value) => {
      if (value) {
        data.value = value
      }
    })
  }

  watch(data, (value) => {
    if (value === null || value === undefined) {
      del(prefixedKey)
      router.push({ query: Object.assign({}, route.query, { [key]: undefined }) })
    }

    set(prefixedKey, value)
    router.push({ query: Object.assign({}, route.query, { [key]: value }) })
  })

  return data
}

/** Persist a value in IndexedDB. */
export function useKeyValStore<T = unknown>(key: IDBValidKey, fallbackValue: T) {
  const data = ref(fallbackValue)

  const prefixedKey = makeIndexedDbKey(key)

  getInitialKey<UnwrapRef<T>>(prefixedKey).then((value) => {
    if (value) {
      data.value = value
    }
  })

  watch(data, (value) => {
    if (value === null || value === undefined) {
      del(prefixedKey)
    }

    set(prefixedKey, value)
  })

  return data
}

type AsyncWriteFile = {
  write(path: Array<string>, fileName: string, buffer: ArrayBuffer): Promise<void>
}

function createUserDirectoryName(userId: string) {
  return `${userId}_folder`
}

async function getUserDirectory(userId: string) {
  const opfsRoot = await navigator.storage.getDirectory()
  return await opfsRoot.getDirectoryHandle(createUserDirectoryName(userId))
}

export function useFileSystem() {
  const store = useStore()

  // Safari doesn't support the createWritable function
  // So we need to run that operation in a worker
  function startWorker() {
    const worker = new Worker(new URL('./opfs-handler.ts', import.meta.url), {
      type: 'module',
    })
    return [worker, Comlink.wrap<AsyncWriteFile>(worker)] as const
  }

  async function writeFiles(id: string, files: File[]) {
    const [worker, asyncFileWriter] = startWorker()

    const userDirectory = createUserDirectoryName(store.state.user.id)

    for (const file of files.values()) {
      const buffer = await file.arrayBuffer()
      await asyncFileWriter?.write(
        [userDirectory, id],
        file.name,
        Comlink.transfer(buffer, [buffer])
      )
    }

    asyncFileWriter[Comlink.releaseProxy]()
    worker.terminate()
  }

  async function readFiles(id: string) {
    const userFolder = await getUserDirectory(store.state.user.id)
    const folder = await userFolder.getDirectoryHandle(id)
    const files = []
    for await (const fileHandler of folder.values()) {
      if (fileHandler.kind === 'file') {
        const file = await (fileHandler as FileSystemFileHandle).getFile()
        files.push(file)
      }
    }
    return files
  }

  async function deleteFiles(id: string) {
    const userFolder = await getUserDirectory(store.state.user.id)
    await userFolder.removeEntry(id, { recursive: true })
  }

  return { writeFiles, readFiles, deleteFiles }
}

export function usePrefilledSupportMessage(workflowId: Ref<string | undefined>) {
  const { data: workflow } = useWorkflow(workflowId)
  const currentProjectId = useCurrentProjectId()
  const currentOrganizationId = useCurrentOrganizationId()
  const { state } = useStore()
  const route = useRoute()

  const problemReportSubject = computed(() => `Problem with Workflow "${workflow.value?.id}"`)
  const problemReportMessage = computed(
    () => `Hello,
I'd like to report a problem with my workflow "${workflow.value?.name}".

Describe your problem here, if you can:`
  )
  const technicalDetails = computed(
    () =>
      `
Technical Details:
  - Workflow ID: ${workflow.value?.id}
  - Task ID: ${route.query.task || '-'}
  - Project ID: ${currentProjectId.value}
  - Affiliation ID: ${currentOrganizationId.value}
  - Workflow status: ${workflow.value?.status} (${new Date().toISOString()})
  - User ID: ${state.user.id}
  - Browser: ${navigator.userAgent}`
  )

  return { problemReportSubject, problemReportMessage, technicalDetails }
}

export function useConvertEnergyUnits() {
  // could come from the user's settings
  const targetUnit = 'eV'

  function convertEnergy(
    energy?: Partial<Energy>
  ): Energy['data'] extends number ? number : undefined

  function convertEnergy(energy?: Partial<Energy>) {
    if (energy === undefined || energy.data === undefined) {
      return undefined
    }
    if (energy.units === targetUnit) {
      return energy.data
    }
    if (energy.units === 'Eh' && targetUnit === 'eV') {
      return Eh2eV(energy.data)
    }
    return undefined
  }

  return {
    convertEnergy,
    formatEnergy(energy?: Partial<Energy>) {
      return formatNumberScientific(convertEnergy(energy), {
        maximumFractionDigits: 4,
      })
    },
  }
}

export function useProjectInvitationResult(isPageLoaded: ComputedRef<boolean>) {
  const { createToast } = useToast()
  const invitation = useRouteQuery('invitation_status')

  watch(
    [() => invitation, isPageLoaded],
    ([newInvitation, isPageLoaded]) => {
      // Delay showing the toast until the page has loaded
      if (!newInvitation.value || !isPageLoaded) {
        return
      }

      let toastParams
      switch (newInvitation.value) {
        case '200':
          toastParams = {
            title: 'Invitation accepted',
            type: 'success' as ToastCardType,
            description: 'Congrats! You successfully joined the project.',
            duration: 5000,
          }
          break
        case '403':
          toastParams = {
            title: 'Invitation and account mismatch',
            type: 'error' as ToastCardType,
            description: 'This invitation is associated with a different user account.',
          }
          break
        case '404':
          toastParams = {
            title: 'Invitation not found',
            type: 'error' as ToastCardType,
            description: 'The invitation to the project could not be found.',
          }
          break
        case '410':
          toastParams = {
            title: 'Invitation expired',
            type: 'error' as ToastCardType,
            description: 'The invitation to the project has expired.',
          }
          break
        default:
          toastParams = {
            title: 'Error',
            type: 'error' as ToastCardType,
            description:
              'We could not process your invitation at this time. Please try again later.',
          }
          break
      }
      createToast(toastParams)
      invitation.value = null
    },
    { immediate: true }
  )
}
