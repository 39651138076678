import * as VueRouter from 'vue-router'

import AppShell from './components/AppShell.vue'
import config from './config'
import { featureFlagsId } from '~/components/FeatureFlags/use-feature-flags'

// `meta.title` is used solely for matomo tracking

const routes: VueRouter.RouteRecordRaw[] = [
  { path: '/index.html', redirect: '/' },
  {
    path: '/auth/welcome',
    component: () => import('./pages/auth/AuthWelcomePage.vue'),
  },
  {
    path: '/auth/error',
    component: () => import('./pages/auth/AuthErrorPage.vue'),
  },
  {
    path: '/',
    component: AppShell,
    children: [
      {
        path: '',
        component: () => import('./pages/home/Home.vue'),
      },
      {
        path: 'workflows/:workflowId',
        component: () => import('./pages/workflows/_id/Viewer.vue'),
        meta: { title: 'workflow-viewer' },
      },
      {
        path: '/create',
        component: () => import('./pages/TemplateGallery.vue'),
      },
      {
        path: '/create/compound',
        component: () => import('./pages/create/Compounds.vue'),
      },
      {
        path: '/create/preparation',
        children: [
          {
            path: 'prepare-liquid',
            component: () => import('./pages/create/preparation/prepare-liquid/PrepareLiquid.vue'),
          },
          {
            path: 'prepare-solvation-shell',
            component: () =>
              import('./pages/create/preparation/prepare-clusters/PrepareClusters.vue'),
          },
          {
            path: 'prepare-marso-polymer-liquid',
            component: () =>
              import('./pages/create/preparation/prepare-marso-polymer/PrepareMarsoPolymer.vue'),
          },
          {
            path: 'prepare-crystal',
            component: () =>
              import('./pages/create/preparation/prepare-crystal/PrepareCrystal.vue'),
          },
          {
            path: 'prepare-crystal-defects',
            component: () =>
              import(
                './pages/create/preparation/prepare-crystal-defects/PrepareCrystalDefects.vue'
              ),
          },
          {
            path: 'prepare-surface',
            component: () =>
              import('./pages/create/preparation/prepare-surface/PrepareSurface.vue'),
          },
          {
            path: 'prepare-surface-adsorbate',
            component: () =>
              import(
                './pages/create/preparation/prepare-surface-adsorbate/PrepareSurfaceAdsorbate.vue'
              ),
          },
        ],
      },
      {
        path: '/create/simulation',
        children: [
          {
            path: 'viscosity',
            component: () =>
              import('./pages/create/simulation/viscosity/CreateViscositySimulation.vue'),
          },
          {
            path: 'spectral-analysis',
            component: () =>
              import(
                './pages/create/simulation/spectral-analysis/CreateSpectralAnalysisSimulation.vue'
              ),
          },
          {
            path: 'bandstructure',
            component: () =>
              import('./pages/create/simulation/bandstructure/CreateBandstructureSimulation.vue'),
          },
          {
            path: 'surface-adsorption',
            component: () =>
              import(
                './pages/create/simulation/surface-adsorption/CreateSurfaceAdsorptionSimulation.vue'
              ),
          },
          {
            path: 'density',
            component: () =>
              import('./pages/create/simulation/density/CreateDensitySimulation.vue'),
          },
          {
            path: 'dos',
            component: () => import('./pages/create/simulation/dos/CreateDOSSimulation.vue'),
          },
          {
            path: 'diffusion',
            component: () =>
              import('./pages/create/simulation/diffusion/CreateDiffusionSimulation.vue'),
          },
          {
            path: 'ionic-conductivity',
            component: () =>
              import(
                './pages/create/simulation/ionic-conductivity/CreateIonicConductivitySimulation.vue'
              ),
          },
          {
            path: 'glass-temperature',
            component: () =>
              import(
                './pages/create/simulation/glass-temperature/CreateGlassTemperatureSimulation.vue'
              ),
          },
          {
            path: 'mechanical-properties',
            component: () =>
              import(
                './pages/create/simulation/mechanical-properties/CreateMechanicalPropertiesSimulation.vue'
              ),
          },
          {
            path: 'nanoreactor',
            component: () =>
              import('./pages/create/simulation/nanoreactor/CreateNanoreactorSimulation.vue'),
          },
          {
            path: 'ocv',
            component: () => import('./pages/create/simulation/ocv/CreateOCVSimulation.vue'),
          },
          {
            path: 'optical-spectrum',
            component: () =>
              import(
                './pages/create/simulation/optical-spectrum/CreateOpticalSpectrumSimulation.vue'
              ),
          },
          {
            path: 'optical-spectrum-analyzer',
            component: () =>
              import('./pages/create/simulation/optical-spectrum-analyzer/CreateOSASimulation.vue'),
          },
        ],
      },
      {
        path: 'compounds/:compoundId',
        component: () => import('./pages/compounds/CompoundViewerPage.vue'),
        meta: { title: 'compound-viewer' },
      },
      {
        path: 'compounds/new',
        component: () => import('./pages/compounds/PubChemCompoundViewer.vue'),
        meta: { title: 'compound-preview-viewer' },
      },
      {
        path: 'preparations/:preparationId',
        component: () => import('./pages/preparations/Viewer.vue'),
        meta: { title: 'preparation-viewer' },
      },
      {
        path: '/:affiliationId',
        component: () => import('./pages/AffiliationDashboard.vue'),
      },
      {
        path: '/:affiliationId/:projectId',
        component: () => import('./pages/projects/ProjectDashboard.vue'),
        children: [
          {
            name: 'workflows',
            path: '',
            alias: 'workflows',
            component: () => import('./pages/projects/WorkflowView.vue'),
          },
          {
            name: 'compounds',
            path: 'compounds',
            component: () => import('./pages/projects/CompoundView.vue'),
          },
        ],
      },
      {
        path: '/account',
        component: () => import('./pages/account/Account.vue'),
      },
    ],
  },
]

routes.push({
  path: '/:pathMatch(.*)*',
  name: 'not-found',
  component: () => import('./pages/NotFoundError.vue'),
})

function createRouter() {
  return VueRouter.createRouter({
    history: VueRouter.createWebHistory(config.baseUrl),
    routes,
  })
}

export const router = createRouter()

router.beforeEach((to, from, next) => {
  if (to.meta.flag === undefined) {
    next()
  }

  if (to.meta.flag !== undefined) {
    const featList = localStorage.getItem(featureFlagsId.value)
    if (featList && JSON.parse(featList)?.[to.meta.flag as string] === true) {
      next()
    } else {
      next({ name: 'not-found' })
    }
  }
})
