import { setUser } from '@sentry/vue'
import debug from 'debug'
import merge from 'lodash/merge'
import { reactive } from 'vue'

import { api } from '../api/quantistry'

type Account = Awaited<ReturnType<typeof api.account.get>>
type AccountUpdateRequest = Parameters<typeof api.account.update>[0]['requestBody']

const log = debug('quantistry:store')

class Store {
  state = reactive({
    isAuthenticated: false,
    user: {} as Account,
  })

  async initialize() {
    log('initializing')
    try {
      const user = await this.fetchUser()
      setUser({ email: user.email, id: user.id, type: user.type })
      this.state.user = user
      this.state.isAuthenticated = true
    } catch {
      this.state.isAuthenticated = false
    }
    log('initialized')
  }

  async fetchUser() {
    const user = await api.account.get()
    return user
  }

  async refetchUser() {
    const user = await this.fetchUser()
    this.state.user = user
  }

  async updateUser(userData: AccountUpdateRequest) {
    // optimistic updates
    this.state.user = merge({}, this.state.user, userData)

    const user = await api.account.update({ requestBody: userData })

    this.state.user = user
  }
}

const store = new Store()

export function useStore() {
  return store
}
